import React from "react"
import { useRecoilState } from "recoil"
import { Link } from "gatsby"
import { collapsedState } from "../../utils/recoil-atoms"
import logo from "../../assets/images/logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight
} from "@fortawesome/free-solid-svg-icons"

const Navbar = ({ showNavigation = true , showNetstorsysButton = false, showNetversysButton = false}) => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState)

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  React.useEffect(() => {
    let elementId = document.getElementById("navbar")
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky")
      } else {
        elementId.classList.remove("is-sticky")
      }
    })
    window.scrollTo(0, 0)
  })

  const classOne = collapsed ? "collapse navbar-collapse" : "collapse navbar-collapse show"
  const classTwo = collapsed ? "navbar-toggler navbar-toggler-right collapsed" : "navbar-toggler navbar-toggler-right"

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area">
        <div className="tarn-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link
                to="/"
                onClick={() => setCollapsed(true)}
                className="navbar-brand"
              >
                <img src={logo} alt="logo" width={"190px"}/>
              </Link>

              {!showNavigation && showNetversysButton &&
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/versandsoftware/" className="btn btn-primary px-3 text-white py-2">
                       NETVERSYS Produktseite
                    </Link>
                  </li>
                </ul>
              }

              {!showNavigation && showNetstorsysButton &&
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/lagerverwaltung/" className="btn btn-primary px-3 text-white py-2">
                    NETSTORSYS Produktseite
                  </Link>
                </li>
              </ul>
              }

              {showNavigation && <>
                <button
                  onClick={toggleNavbar}
                  className={classTwo}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar top-bar" />
                  <span className="icon-bar middle-bar" />
                  <span className="icon-bar bottom-bar" />
                </button>

                <div className={classOne} id="navbarSupportedContent">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link
                        to="/versandsoftware/"
                        activeClassName="active"
                        partiallyActive={true}
                        className="nav-link"
                      >
                        NETVERSYS ∙ Versandsoftware
                      </Link>
                    </li>
                    <li className={"d-none d-lg-block"} style={{alignSelf: "center"}}>|</li>
                    <li className="nav-item">
                      <Link
                        to="/lagerverwaltung/"
                        activeClassName="active"
                        partiallyActive={true}
                        className="nav-link"
                      >
                        NETSTORSYS ∙ Lagerverwaltung
                      </Link>
                    </li>
                    <li className="d-none d-lg-block" style={{alignSelf: "center"}}>|</li>
                    <li className="nav-item">
                      <Link
                        to="/ueber-uns/"
                        activeClassName="active"
                        className="nav-link"
                      >
                        Über uns
                      </Link>
                    </li>
                  </ul>

                  <div className="others-option d-flex align-items-center">
                    <div className="option-item">
                      <Link
                        to="/kontakt/"
                        activeClassName="active"
                        onClick={() => setCollapsed(true)}
                        className="btn btn-primary btn-lg"
                      >
                        <FontAwesomeIcon icon={faArrowRight} size={"sm"} alt={"Kontakt"} />  Kontakt

                      </Link>
                    </div>
                  </div>
                </div>
              </>}
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar
