import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, description, image, meta, link, children }) => {

    const {site} = useStaticQuery(
      graphql`
      query {
        site {
          siteMetadata {
            title
            titleSuffix
            description
            image
            siteUrl
          }
        }
      }
    `);

    const myTitle = title || site.siteMetadata.title

    // determine the social images, using props or default. If props, do some intelligent URL handling
    let myImage;
    if (image) {
        if (image.startsWith('http')) {
            myImage = image;
        } else {
            myImage = site.siteMetadata.siteUrl + image;
        }
    } else {
        myImage = site.siteMetadata.IMAGE;
    }

    const myDescription = description || site.siteMetadata.description

    return (
      <Helmet
        htmlAttributes={{
            lang: "de",
        }}
        title={myTitle}
        //titleTemplate={`%s | ${site.siteMetadata.titleSuffix}`}
        link={[].concat(link)}
        meta={[
            {
                property: `og:title`,
                content: myTitle,
            },
            {
                property: `og:image`,
                content: myImage,
            },
            {
                property: `og:description`,
                content: myDescription,
            },
            {
                property: `og:type`,
                content: `website`,
            },
            {
                name: `description`,
                content: myDescription,
            },
            {
                name: `twitter:card`,
                content: `summary_large_image`,
            },
            {
                name: `twitter:creator`,
                content: "@logentis",
            },
            {
                name: `twitter:title`,
                content: myTitle,
            },
            {
                name: `twitter:description`,
                content: myDescription,
            },
            {
                name: `twitter:site`,
                content: "@logentis",
            },
            {
                name: `twitter:image`,
                content: myImage,
            },
            {
                name: "google-site-verification",
                content: "7HFTexlFT0YFlbNmi7j1x6rrTnzOjvQfONNDKl3PqBI"
            }
        ].concat(meta)}
      >
          {children}
      </Helmet>
    )
}

export default Seo

Seo.defaultProps = {
    meta: [],
    link: [],
    description: ``,
    title: null,
    image: null,
}

Seo.propTypes = {
    description: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    link: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    image: PropTypes.string
}
